function convertCity(city) {
    const rules = [
        { regex: /а$|ль$|ня$|ея$|ля$|зя$|ай$/, replace: city => city.slice(0, -1) + 'е' },
        { regex: /р$|ск$|д$|ш$|ж$|ов$|ан$|цк$|г$|ул$|ток$|оп$|с$|т$|еп$|ин$|ик$|ен$|ол$|ом$|ач$|п$|он$|як$|уз$|ян$|ак$/, replace: city => city + 'е' },
        { regex: /рь$|мь$|ия$|нь$|шь$/, replace: city => city.slice(0, -1) + 'и' },
        { regex: /ний$/, replace: city => city.slice(0, -2) + 'ем' },
        { regex: /кий$|ый$|ое$/, replace: city => city.slice(0, -2) + 'ом' },
        { regex: /жок$/, replace: city => city.slice(0, -2) + 'ке' },
        { regex: /кое$/, replace: city => city.slice(0, -2) + 'ом' },
        { regex: /ел$/, replace: city => city.slice(0, -2) + 'ле' },
        { regex: /ы$|и$/, replace: city => city.slice(0, -1) + 'ах' },
        { regex: /лец$/, replace: city => city.slice(0, -3) + 'льце' },
        { regex: /ец$/, replace: city => city.slice(0, -2) + 'це' },
        { regex: /яя$/, replace: city => city.slice(0, -2) + 'ей' },
        { regex: /ая$/, replace: city => city.slice(0, -2) + 'ой' },
        { regex: /ие$/, replace: city => city.slice(0, -1) + 'х' },
    ];

    for (const rule of rules) {
        if (rule.regex.test(city)) {
            return rule.replace(city);
        }
    }

    return city;
}

// склонение городов (например: Москва -> Москве)
export function cityDeclension(city) {
    const moreThanOneWord = /\b[А-Яа-я]+\s[А-Яа-я]+/u; // Город [пробел] Город (Нижний Новгород, Великий Устюг)
    const dashedWord = /\b[А-Яа-я]+-на-[А-Яа-я]+\b/u; // Город[дефис]на[дефис]Город (Ростов-на-Дону, Славянск-на-Кубани)

    if (moreThanOneWord.test(city)) {
        // Если город с пробелами
        return city.split(' ').map(convertCity)
            .join(' ');
    }
    if (dashedWord.test(city)) {
        // Если город с дефисами (...-на-...)
        const [firstPart, ...rest] = city.split('-');
        rest[0] = convertCity(rest[0]);
        return [firstPart, ...rest].join('-');
    }
    // Если город без пробелов и дефисов
    return convertCity(city);
}
